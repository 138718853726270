@media (max-width: 767px) {
  .row.pr-5.ps-3 {
    padding-left: 0;
  }
}

.input-form-responsive-container {
  width: 55%;
  margin: 0 auto;
}

@media (max-width: 1200px) and (min-width: 992px) {
  .input-form-responsive-container {
    width: 70%;
  }
}

@media (max-width: 992px) {
  .input-form-responsive-container {
    width: 100%;
  }
}
